import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  standalone: true,
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrl: "./message.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, MatIcon, MatProgressSpinner],
})
export class MessageComponent {
  public readonly type = input.required<"error" | "success" | "info">();
  public readonly messageKey = input.required<string>();
  public readonly icon = input<string>();
  public readonly showLoading = input<boolean>(false);
}
