import { Component, computed, input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { lastValueFrom } from "rxjs";
import { PreApprovePartnerCampaignInteractor } from "../../../../features/partner-campaign/domain/interactors/pre-approve-partner-campaign.interactor";
import { SolvePartnerCampaignIssuesInteractor } from "../../../../features/partner-campaign/domain/interactors/solve-partner-campaign-issues.interactor";
import {
  CampaignPartnerStatus,
  PaymentType,
} from "../../../../shared/enums/campaign.enums";
import {
  PostPartnerStatus,
  PostPlatform,
} from "../../../../shared/enums/campaignPost.enums";
import { Partner } from "../../../../shared/models/partner";
import {
  PartnerCampaign,
  PreApprovalSource,
} from "../../../../shared/models/partnerCampaign";
import { PostLog } from "../../../../shared/models/postLog";
import { PartnerCampaignService } from "../../../../shared/services/api/partner-campaign.service";
import { PartnerService } from "../../../../shared/services/api/partner.service";
import { cloneWith } from "../../../../shared/services/clonable";
import { NotificationService } from "../../../../shared/services/notification.service";
import { PartnerDialogService } from "../../../partner-dialog.service";

@Component({
  selector: "app-partner-campaign-header-notice",
  templateUrl: "./partner-campaign-header-notice.component.html",
  styleUrls: ["./partner-campaign-header-notice.component.scss"],
})
export class PartnerCampaignHeaderNoticeComponent {
  public readonly campaign = input.required<PartnerCampaign>();
  constructor(
    private readonly partnerDialogService: PartnerDialogService,
    private readonly preApprovePartnerCampaign: PreApprovePartnerCampaignInteractor,
    private readonly solveIssues: SolvePartnerCampaignIssuesInteractor,
    private readonly partnerCampaignService: PartnerCampaignService,
    private readonly partnerService: PartnerService,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
  ) {}

  protected readonly isStatusCancelled = computed(
    () =>
      this.campaign().campaignPartnerStatus === CampaignPartnerStatus.Cancelled,
  );

  protected readonly isStatusInvited = computed(
    () =>
      this.campaign().campaignPartnerStatus === CampaignPartnerStatus.Invited,
  );

  protected readonly isStatusPendingAndPreApproved = computed(
    () =>
      [
        CampaignPartnerStatus.PendingValidation,
        CampaignPartnerStatus.Accepted,
      ].includes(this.campaign().campaignPartnerStatus) &&
      this.campaign().preApproved,
  );

  protected readonly canInitiatePreApproval = computed(() => {
    if (this.campaign().isBeta) {
      return false;
    }

    return (
      [
        CampaignPartnerStatus.PendingValidation,
        CampaignPartnerStatus.Accepted,
      ].includes(this.campaign().campaignPartnerStatus) &&
      !this.campaign().preApproved
    );
  });

  protected readonly isGeneratingContentCalendar = computed(
    () =>
      this.campaign().isContentCalendarBeingGenerated &&
      !this.campaign().isBeta,
  );

  protected readonly approvalFailed = computed(() => {
    if (!this.campaign().hasPartnerApprovedContentCalendar) {
      return false;
    }

    return this.campaign().currentCalendar?.calendarPosts.some(
      (post: PostLog) => post.partnerStatus === PostPartnerStatus.Pending,
    );
  });

  protected readonly isPendingPreApprovalExecution = computed(
    () =>
      this.campaign().preApproved &&
      !this.campaign().isContentCalendarBeingGenerated &&
      !this.campaign().hasPartnerApprovedContentCalendar,
  );

  protected readonly isPreApproved = computed(
    () => this.campaign().preApproved,
  );

  protected async onActionPreApprove(): Promise<void> {
    if (this.campaign().isPartnerPendingToDefineACustomCampaignBudget) {
      const result = await this.partnerDialogService.showConfirmBudgetDialog({
        campaign: this.campaign(),
      });

      if (!result) {
        return;
      }
    }

    const source = PreApprovalSource.Campaign;

    const partner = this.partnerService.currentPartner;

    if (this.canPreApproveCampaign()) {
      await this.preApproveCampaign(partner, source);
      return;
    }

    const hasPartnerSomeConnection =
      await this.partnerDialogService.showThirdPartyConnectionsCheck({
        context: "pre-approve",
      });

    if (hasPartnerSomeConnection) {
      await this.preApproveCampaign(partner, source);
    }
  }

  protected async onActionSolveIssues(): Promise<void> {
    const hasPartnerSomeConnection =
      await this.partnerDialogService.showThirdPartyConnectionsCheck({
        context: "solve-issues",
      });

    const partner = this.partnerService.currentPartner;

    if (hasPartnerSomeConnection) {
      await this.solvePendingIssues(partner);
    }
  }

  private async solvePendingIssues(partner: Partner): Promise<void> {
    try {
      const response = await this.solveIssues.execute(
        partner.id,
        this.campaign().id,
      );

      await lastValueFrom(
        this.partnerCampaignService.loadPartnerCampaign(this.campaign().id),
      );

      if (response.pendingPostsAfter === 0) {
        this.notificationService.success("partner.campaign.issuesSolved");
        return;
      }
      if (response.pendingPostsAfter === response.pendingPostsBefore) {
        this.notificationService.error("partner.campaign.noIssuesSolved");
        return;
      }
      const message = this.translateService.instant(
        "partner.campaign.issuesPartiallySolved",
        {
          scheduled: response.pendingPostsBefore - response.pendingPostsAfter,
          remaining: response.pendingPostsBefore,
        },
      );
      this.notificationService.success(message);
    } catch (e) {
      this.notificationService.error("errorDialog.unexpectedError");
    }
  }

  private canPreApproveCampaign(): boolean {
    const platforms = this.campaign().getPlatformsInUse();
    const partner = this.partnerService.currentPartner;
    let hasSomeConnection = false;

    if (platforms.includes(PostPlatform.Facebook) && partner.isConnectedToFB) {
      hasSomeConnection = true;
    }
    if (
      platforms.includes(PostPlatform.Google) &&
      partner.areGoogleAdsAuthorized
    ) {
      hasSomeConnection = true;
    }
    if (
      platforms.includes(PostPlatform.LinkedIn) &&
      partner.isConnectedToLinkedIn
    ) {
      hasSomeConnection = true;
    }

    const isShortUrlMissing =
      this.campaign().hasLandingPageRetailerOwnLink &&
      this.campaign().partner.isShortUrlMissing;
    const isVatMissing =
      (this.campaign().paymentType === PaymentType.CoFunded ||
        this.campaign().paymentType === PaymentType.Self) &&
      this.campaign().budget > 0 &&
      !partner.vatNumber;

    return hasSomeConnection && !isShortUrlMissing && !isVatMissing;
  }

  private async preApproveCampaign(
    partner: Partner,
    source: PreApprovalSource,
  ): Promise<void> {
    try {
      await this.preApprovePartnerCampaign.execute(
        source,
        partner.id,
        this.campaign().id,
      );

      const currentPartnerCampaign = await lastValueFrom(
        this.partnerCampaignService.getCurrentPartnerCampaign(),
      );

      const updatedPartnerCampaign = cloneWith(currentPartnerCampaign, {
        preApproved: true,
      });

      this.partnerCampaignService.partnerCampaignUpdated.emit(
        updatedPartnerCampaign,
      );

      this.notificationService.success("partner.campaign.preApproved");
    } catch (e) {
      this.notificationService.error("errorDialog.unexpectedError");
    }
  }
}
