import { Component, inject, OnInit } from "@angular/core";
import { MatProgressBar } from "@angular/material/progress-bar";
import { ActivatedRoute, Params, Router } from "@angular/router";

import { AuthenticationService } from "../../../shared/services/api/authentication.service";
import { UserAccessService } from "../../user-access.service";

@Component({
  standalone: true,
  selector: "app-single-sign-on-callback",
  templateUrl: "./single-sign-on-callback.component.html",
  styleUrl: "../single-sign-on.scss",
  imports: [MatProgressBar],
})
export class SingleSignOnCallbackComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly userAccessService = inject(UserAccessService);

  private accessToken?: string;

  constructor(activatedRoute: ActivatedRoute) {
    activatedRoute.queryParams.subscribe((params: Params) => {
      this.accessToken = params.access_token;
    });
  }

  public ngOnInit(): void {
    this.startSSOLogin();
  }

  private startSSOLogin(): void {
    if (!this.accessToken) {
      this.redirectAfterFail();
      return;
    }

    this.authenticationService.storeUserSession(this.accessToken);
    this.userAccessService.setUserData().subscribe(() => {
      // ...and navigate to the welcome page
      void this.router.navigate(["welcome"]);
    });
  }

  private redirectAfterFail(): void {
    void this.router.navigate(["login"]);
  }
}
