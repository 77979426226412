import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { StrictApiRequestService } from "../shared/http/strict-api-request.service";
import { AuthNetworkService } from "../shared/services/api/auth.network.service";

@Injectable({
  providedIn: "root",
})
export class InviteService {
  private readonly apiRequestService: StrictApiRequestService;

  constructor(
    @Inject("MONOLITH_API_URL")
    API_URL: string,
    httpClient: HttpClient,
    authNetworkService: AuthNetworkService,
  ) {
    this.apiRequestService = new StrictApiRequestService(
      API_URL,
      httpClient,
      authNetworkService,
    );
  }

  public async joinCampaign(token: string): Promise<void> {
    return await this.apiRequestService
      .path(`/public/partner-accept-campaign/${token}`)
      .post();
  }

  public async approveCampaign(token: string): Promise<void> {
    return await this.apiRequestService
      .path(`/public/partner-accept-and-approve-campaign/${token}`)
      .post();
  }

  public async declineInvitation(token: string): Promise<void> {
    return await this.apiRequestService
      .path(`/public/partner-decline-campaign/${token}`)
      .post();
  }
}
