<form name="form" (ngSubmit)="acceptCampaign()" #f="ngForm" novalidate>
  @if (isSubmitted()) {
    <div class="form-body-success">
      <h2>{{ "partner.acceptCampaign.successText" | translate }}</h2>
    </div>
  } @else {
    <!-- Invite Form -->
    <div class="form-body">
      <button class="btn btn--primary full-width" [disabled]="isSubmitting()">
        {{ "partner.acceptCampaign.joinCampaignText" | translate }}
      </button>
      <a class="decline-link" (click)="declineCampaignConfirm()">
        {{ "partner.acceptCampaign.declineText" | translate }}
      </a>
    </div>
  }
</form>
