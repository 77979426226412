import { Component, inject, OnDestroy, OnInit, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { Router, RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { mergeMap } from "rxjs/operators";

import { User } from "../../shared/models/user";
import { AuthenticationService } from "../../shared/services/api/authentication.service";
import { UserService } from "../../shared/services/api/user.service";
import { DialogService } from "../../shared/services/dialog.service";
import { LanguageService } from "../../shared/services/language.service";
import { NotificationService } from "../../shared/services/notification.service";

@Component({
  standalone: true,
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.scss"],
  imports: [
    FormsModule,
    TranslateModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    RouterLink,
  ],
})
export class EditUserComponent implements OnInit, OnDestroy {
  private readonly authenticationService = inject(AuthenticationService);
  private readonly dialogService = inject(DialogService);
  private readonly languageService = inject(LanguageService);
  private readonly notificationService = inject(NotificationService);
  private readonly router = inject(Router);
  private readonly userService = inject(UserService);

  private readonly subscriptions = new Subscription();

  protected readonly isSSO = signal<boolean | undefined>(undefined);
  protected user!: User;

  public ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  public ngOnInit(): void {
    this.user = new User();
    const getUserSubscribe = this.userService
      .get()
      .pipe(
        mergeMap((currentUser) => {
          this.user = currentUser;

          // Override user's locale if it's different than the one saved in localStorage
          const locale = this.languageService.locale;

          if (this.user.locale !== locale) {
            this.user.locale = locale;
          }

          return this.authenticationService.isSSoUser(this.user.email);
        }),
      )
      .subscribe((isSSOUserResponse) => {
        this.isSSO.set(isSSOUserResponse.isSSO);
      });

    this.subscriptions.add(getUserSubscribe);
  }

  protected update(): void {
    const userUpdate = this.userService
      .update(this.user)
      .subscribe((result) => {
        this.user = result;
        // update username in localStorage
        this.authenticationService.setUserName(this.user.email);
        this.notificationService.success("userAccount.userUpdated");
      });

    this.subscriptions.add(userUpdate);
  }

  protected confirmDeleteUser(): void {
    this.dialogService.confirm({
      title: "userAccount.deleteUserConfirmTitle",
      message: "userAccount.deleteUserConfirmDescription",
      onConfirm: () => this.deleteUser(),
    });
  }

  private deleteUser(): void {
    const deleteUser = this.userService.delete().subscribe({
      next: () => {
        this.router.navigate(["/logout"]);
      },
      error: () => {
        this.notificationService.success("userAccount.deleteUserError");
      },
    });

    this.subscriptions.add(deleteUser);
  }
}
