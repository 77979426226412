import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { CustomPreloadingStrategy } from "./app.preloading";
import { PublicAuthRoutes } from "./auth/auth.routes";
import { CampaignChartsScreenshotComponent } from "./screenshot/campaign-charts-screenshot/campaign-charts-screenshot.component";
import { ErrorComponent } from "./shared/components/error/error.component";
import { NotFoundComponent } from "./shared/components/not-found/not-found.component";
import { AuthGuard } from "./shared/guards/auth.guard";
import { BrowserDetectionGuard } from "./shared/guards/browser-detection.guard";
import { PublicAuthGuard } from "./shared/guards/public-auth.guard";
import { AccountVerifiedComponent } from "./static/account-verified/account-verified.component";
import { BrandEmailConnectedComponent } from "./static/brand-email-connected/brand-email-connected.component";
import { SamplePageComponent } from "./static/sample-page/sample-page.component";
import { UnsupportedBrowserComponent } from "./static/unsupported-browser/unsupported-browser.component";
import { VerifyAccountErrorComponent } from "./static/verify-account-error/verify-account-error.component";
// TODO: send to the corresponding module instead of importing all these components
import { SingleSignOnCallbackComponent } from "./auth/sso/single-sign-on-callback/single-sign-on-callback.component";
import { SingleSignOnIframeComponent } from "./auth/sso/single-sign-on-iframe/single-sign-on-iframe.component";

export const CustomRouterErrorHandler = (error: unknown) => {
  console.error("RouterErrorHandler: " + error);
  throw error;
};

const routes: Routes = [
  // Public routes
  ...PublicAuthRoutes,
  {
    path: "no-cookies",
    loadChildren: () =>
      import("./static/no-cookies/no-cookies.module").then(
        (m) => m.NoCookiesModule,
      ),
  },
  {
    path: "sso-callback",
    component: SingleSignOnCallbackComponent,
    canActivate: [BrowserDetectionGuard, PublicAuthGuard],
  },
  {
    path: "sso-iframe",
    component: SingleSignOnIframeComponent,
    canActivate: [BrowserDetectionGuard],
  },
  {
    path: "campaign-charts-screenshot/:token/:screen",
    component: CampaignChartsScreenshotComponent,
    canActivate: [BrowserDetectionGuard, PublicAuthGuard],
    children: [{ path: "", component: CampaignChartsScreenshotComponent }],
  },

  // Secure routes (user must be logged in using OAuth grant_type 'password')
  {
    path: "",
    loadChildren: () =>
      import("./app-private-routing.module").then(
        (m) => m.AppPrivateRoutingModule,
      ),
    canActivate: [BrowserDetectionGuard, AuthGuard],
    data: { preload: true },
  },
  {
    path: "unsupported-browser",
    component: UnsupportedBrowserComponent,
  },
  {
    path: "brand-email-connected",
    component: BrandEmailConnectedComponent,
  },
  {
    path: "account-verified",
    component: AccountVerifiedComponent,
  },
  {
    path: "sample-page",
    component: SamplePageComponent,
  },
  {
    path: "verify-account-error",
    component: VerifyAccountErrorComponent,
  },
  {
    path: "error",
    component: ErrorComponent,
    canActivate: [BrowserDetectionGuard],
  },
  {
    path: "not-found",
    component: NotFoundComponent,
    canActivate: [BrowserDetectionGuard],
  },
  {
    path: "**",
    component: NotFoundComponent,
    canActivate: [BrowserDetectionGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      errorHandler: CustomRouterErrorHandler,
      preloadingStrategy: CustomPreloadingStrategy,
    }),
  ],
  providers: [
    BrowserDetectionGuard,
    PublicAuthGuard,
    CustomPreloadingStrategy,
    AuthGuard,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
