<div class="container">
  <div class="floating-box">
    <div class="floating-box__header">
      <h1 class="floating-box__title">
        {{ "shared.userInformation" | translate }}
      </h1>
    </div>
    <div class="floating-box__content">
      <form
        class="floating-box__form"
        #form="ngForm"
        (ngSubmit)="form.form.valid && update()"
        novalidate
      >
        <fieldset>
          <mat-form-field class="floating-box__input-container">
            <mat-label>{{ "shared.email" | translate }}</mat-label>
            <input
              #username="ngModel"
              matInput
              name="email"
              type="email"
              required
              [(ngModel)]="user.email"
              disabled
              email
            />
          </mat-form-field>
          @if (username.errors?.required) {
            <mat-error>
              {{ "shared.emailAddressIsRequired" | translate }}
            </mat-error>
          } @else if (username.errors?.email) {
            <mat-error>
              {{ "shared.enterAValidEmailAddress" | translate }}
            </mat-error>
          }
        </fieldset>
        @if (!isSSO()) {
          <fieldset>
            <mat-form-field class="floating-box__input-container">
              <mat-label>{{ "shared.password" | translate }}</mat-label>
              <input matInput type="text" value="••••••••••••••••" disabled />
            </mat-form-field>
          </fieldset>
        }
      </form>
      <a
        class="floating-box__button-edit-password"
        [routerLink]="['/user/password']"
      >
        {{ "userAccount.editPassword" | translate }}
      </a>
    </div>
  </div>
  <div class="action-bar">
    <button
      class="action-bar__button action-bar__button--delete"
      (click)="confirmDeleteUser()"
    >
      {{ "userAccount.deleteUser" | translate }}
    </button>
    <button
      class="action-bar__button--cancel btn btn--transparent"
      type="button"
      [routerLink]="['/']"
    >
      {{ "shared.cancel" | translate }}
    </button>
    <button class="action-bar__button action-bar__button--save" type="submit">
      {{ "shared.save" | translate }}
    </button>
  </div>
</div>
