import { Routes } from "@angular/router";
import { BrowserDetectionGuard } from "../shared/guards/browser-detection.guard";
import { PublicAuthGuard } from "../shared/guards/public-auth.guard";
import { AuthPageComponent } from "./auth-page/auth-page.component";
import { EditPasswordComponent } from "./edit-password/edit-password.component";
import { EditUserComponent } from "./edit-user/edit-user.component";
import { LoginComponent } from "./login/login.component";
import { RecoverPasswordComponent } from "./recover-password/recover-password.component";
import { SignUpComponent } from "./sign-up/sign-up.component";

export const PublicAuthRoutes: Routes = [
  {
    path: "",
    component: AuthPageComponent,
    canActivate: [BrowserDetectionGuard, PublicAuthGuard],
    children: [
      { path: "", redirectTo: "login", pathMatch: "full" },
      {
        path: "login",
        component: LoginComponent,
        data: { scenario: "auth" },
      },
      {
        path: "invite",
        component: SignUpComponent,
        data: { scenario: "auth" },
      },
      {
        path: "user-invite/:activation_code",
        component: SignUpComponent,
        data: { scenario: "auth" },
      },
      { path: "signup", redirectTo: "invite" },
      {
        path: "recover-password",
        component: RecoverPasswordComponent,
        data: { scenario: "auth" },
      },
    ],
  },
  {
    path: "invite/:slug",
    component: AuthPageComponent,
    canActivate: [BrowserDetectionGuard],
    children: [{ path: "", component: SignUpComponent }],
    data: { scenario: "invite" },
  },
  {
    // This is used for the brand invite page preview
    path: "invite/:slug/preview",
    component: AuthPageComponent,
    canActivate: [BrowserDetectionGuard],
    children: [{ path: "", component: SignUpComponent }],
    data: { scenario: "preview" },
  },
  {
    path: "partner-accept-campaign/:partner_invite_token",
    component: AuthPageComponent,
    canActivate: [BrowserDetectionGuard],
    data: { scenario: "invite" },
  },
  {
    path: "signup/:slug",
    component: AuthPageComponent,
    canActivate: [BrowserDetectionGuard, PublicAuthGuard],
    data: { scenario: "invite" },
  },
];

export const ProtectedAuthRoutes: Routes = [
  {
    path: "user",
    children: [
      { path: "profile", component: EditUserComponent },
      { path: "password", component: EditPasswordComponent },
    ],
  },
];
