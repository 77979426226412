@if (isSubmitted()) {
  <span class="title">{{ "invite.signUpSuccess" | translate }}</span>
} @else {
  <span class="title">{{ "invite.signUp" | translate }}</span>
}

@if (form) {
  <form [formGroup]="form" novalidate>
    <!-- Invite Form -->
    @if (!isSubmitted()) {
      <section>
        <mat-form-field class="full-width">
          <mat-label>{{ "invite.emailPlaceholder" | translate }}</mat-label>
          <input matInput type="email" formControlName="email" />
          @if (form.controls.email.errors?.required) {
            <mat-error>{{ "invite.emailRequired" | translate }}</mat-error>
          } @else if (form.controls.email.errors?.email) {
            <mat-error>{{ "invite.enterValidEmail" | translate }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>{{ "invite.passwordPlaceholder" | translate }}</mat-label>
          <input
            matInput
            [type]="showPassword() ? 'text' : 'password'"
            formControlName="password"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="showPassword.set(!showPassword())"
            [attr.aria-label]="
              (showPassword() ? 'invite.hidePassword' : 'invite.showPassword')
                | translate
            "
            [attr.aria-pressed]="!showPassword()"
          >
            <mat-icon>
              {{ showPassword() ? "visibility" : "visibility_off" }}
            </mat-icon>
          </button>
          @if (form.controls.password.errors?.required) {
            <mat-error>
              {{ "invite.passwordRequired" | translate }}
            </mat-error>
          } @else if (form.controls.password.errors?.pattern) {
            <mat-error>
              {{ "invite.passwordPattern" | translate }}
            </mat-error>
          }
        </mat-form-field>
        <div class="extra-field">
          <div class="terms-input">
            <mat-checkbox formControlName="areTermsAccepted" />
            <div
              class="terms-text"
              [innerHTML]="'invite.terms' | translate"
            ></div>
          </div>
          @if (
            form.controls.areTermsAccepted.errors?.required &&
            shouldShowTermsError()
          ) {
            <mat-error>
              {{ "invite.acceptTerms" | translate }}
            </mat-error>
          }
        </div>
      </section>
      <section>
        <button
          class="btn btn--primary full-width"
          [disabled]="isSubmitting()"
          (click)="signup()"
        >
          {{ "invite.createAccount" | translate }}
        </button>
        <div class="switch-form">
          <span>{{ "invite.alreadyHaveAccount" | translate }}</span>
          <a [routerLink]="'/login'">
            <strong>{{ "login.loginButton" | translate }}</strong>
          </a>
        </div>
      </section>
    }
  </form>
}
