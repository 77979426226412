import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Subscription, lastValueFrom } from "rxjs";
import { PaymentType } from "../../../shared/enums/campaign.enums";
import { PostLogStatus } from "../../../shared/enums/campaignPost.enums";
import dayjs from "dayjs";

import { Partner } from "../../../shared/models/partner";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PostLog } from "../../../shared/models/postLog";
import { PartnerCampaignPostLogService } from "../../shared/services/partner-campaign-post-log.service";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";
import { PartnerCampaignPostsDialogComponent } from "../partner-campaign-posts-dialog/partner-campaign-posts-dialog.component";
import { PartnerDialogService } from "../../partner-dialog.service";
import { PartnerService } from "../../../shared/services/api/partner.service";

@Component({
  selector: "app-partner-campaign-posts",
  templateUrl: "./partner-campaign-posts.component.html",
  styleUrl: "./partner-campaign-posts.component.scss",
})
export class PartnerCampaignPostsComponent implements OnInit, OnDestroy {
  protected readonly PostLogStatus = PostLogStatus;
  protected campaign: PartnerCampaign;
  protected loading = false;
  protected postsTabsSelectedIndex = 0;
  protected publishedPosts!: PostLog[];
  protected reloading = false;
  protected scheduledPosts!: PostLog[];

  private readonly subscription = new Subscription();
  private partner: Partner;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly partnerCampaignPostLogService: PartnerCampaignPostLogService,
    private readonly partnerCampaignService: PartnerCampaignService,
    private readonly partnerDialogService: PartnerDialogService,
    private readonly partnerService: PartnerService,
  ) {
    this.partner = this.partnerService.currentPartner;
    this.campaign = this.partnerCampaignService.currentCampaign!;
  }

  public ngOnInit(): void {
    window.scrollTo(0, 0);
    this.loading = true;
    this.initData(this.campaign);

    this.subscription.add(
      this.partnerCampaignService.partnerCampaignUpdated.subscribe((campaign) =>
        this.initData(campaign),
      ),
    );

    this.subscription.add(
      this.partnerCampaignPostLogService.postLogCreatedOrUpdated.subscribe(
        () => {
          this.refreshCampaign();
        },
      ),
    );

    this.postsTabsSelectedIndex =
      this.activatedRoute.snapshot.params.tab === "scheduled" ? 1 : 0;
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private initData(campaign: PartnerCampaign): void {
    this.campaign = campaign;

    this.scheduledPosts = this.campaign.posts
      .filter((post) => !post.publishedByPlatform)
      .sort(
        (p1, p2) =>
          Number(dayjs(p1.scheduledPublishDate).format("X")) -
          Number(dayjs(p2.scheduledPublishDate).format("X")),
      );

    this.publishedPosts = this.campaign.posts
      .filter((post) => post.publishedByPlatform)
      .sort(
        (p1, p2) =>
          Number(dayjs(p1.scheduledPublishDate).format("X")) -
          Number(dayjs(p2.scheduledPublishDate).format("X")),
      )
      .reverse();

    this.loading = false;
    this.reloading = false;
  }

  protected async newAd(): Promise<void> {
    this.dialog.closeAll();

    if (this.campaign.isPartnerPendingToDefineACustomCampaignBudget) {
      const result = await this.partnerDialogService.showConfirmBudgetDialog({
        campaign: this.campaign,
      });

      if (!result) {
        return;
      }
    }

    if (
      this.campaign.paymentType !== PaymentType.Brand &&
      !this.partner.vatNumber
    ) {
      const response = await this.partnerDialogService.showVatInfoDialog({
        partner: this.partner,
      });

      if (!(response instanceof Partner) || !response.vatNumber) {
        return;
      }
      this.partner.vatNumber = response.vatNumber;
    }

    this.dialog.open(PartnerCampaignPostsDialogComponent, {
      data: {
        campaign: this.campaign,
        partner: this.partner,
      },
      disableClose: true,
    });
  }

  protected async refreshCampaign(): Promise<void> {
    this.reloading = true;

    const campaign = await lastValueFrom(
      this.partnerCampaignService.getCurrentPartnerCampaign(true),
    );

    if (campaign) {
      this.initData(campaign);
    }

    this.reloading = false;
  }
}
