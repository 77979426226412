import { NgModule } from "@angular/core";
import {
  ApiRequestService,
  Cached,
  provideDefaultNetworkDataSource,
  PutInteractor,
  PutRepository,
  PutRepositoryMapper,
  SinglePutDataSourceRepository,
} from "../../../harmony/core";
import { PartnerCampaignService } from "../../shared/services/api/partner-campaign.service";
import { SolveIssuesResponseEntity } from "./data/entities/solve-issues-response.entity";
import {
  SolveIssuesResponseEntityToSolveIssuesResponseMapper,
  SolveIssuesResponseToSolveIssuesResponseEntityMapper,
} from "./data/solve-issues-response.mapper";
import { PreApprovePartnerCampaignInteractor } from "./domain/interactors/pre-approve-partner-campaign.interactor";
import { SolvePartnerCampaignIssuesInteractor } from "./domain/interactors/solve-partner-campaign-issues.interactor";
import { SolveIssuesResponse } from "./domain/models/solve-issues-response";

export abstract class PartnerCampaignProvider {
  abstract getPreApprovePartnerCampaign(
    partnerCampaignService: PartnerCampaignService,
  ): PreApprovePartnerCampaignInteractor;
  abstract getSolvePartnerCampaignIssues(
    partnerCampaignService: PartnerCampaignService,
  ): SolvePartnerCampaignIssuesInteractor;
}

export class PartnerCampaignDefaultProviders extends PartnerCampaignProvider {
  constructor(private readonly apiRequestService: ApiRequestService) {
    super();
  }

  @Cached()
  private preApproveRepository(): PutRepository<void> {
    const dataSource = provideDefaultNetworkDataSource<void>(
      this.apiRequestService,
    );
    return new SinglePutDataSourceRepository<void>(dataSource);
  }

  @Cached()
  private solveIssuesRepository(): PutRepositoryMapper<
    SolveIssuesResponseEntity,
    SolveIssuesResponse
  > {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      SolveIssuesResponseEntity,
    );
    const repository = new SinglePutDataSourceRepository(dataSource);

    return new PutRepositoryMapper(
      repository,
      new SolveIssuesResponseEntityToSolveIssuesResponseMapper(),
      new SolveIssuesResponseToSolveIssuesResponseEntityMapper(),
    );
  }

  public getPreApprovePartnerCampaign(): PreApprovePartnerCampaignInteractor {
    return new PreApprovePartnerCampaignInteractor(
      new PutInteractor<void>(this.preApproveRepository()),
    );
  }

  public getSolvePartnerCampaignIssues(): SolvePartnerCampaignIssuesInteractor {
    return new SolvePartnerCampaignIssuesInteractor(
      new PutInteractor(this.solveIssuesRepository()),
    );
  }
}

export const PARTNER_CAMPAIGN_PRE_APPROVE_INTERACTOR_PROVIDER = [
  {
    provide: PreApprovePartnerCampaignInteractor,
    deps: [PartnerCampaignProvider, PartnerCampaignService],
    useFactory: (
      partnerCampaignProvider: PartnerCampaignProvider,
      partnerCampaignService: PartnerCampaignService,
    ) =>
      partnerCampaignProvider.getPreApprovePartnerCampaign(
        partnerCampaignService,
      ),
  },
];

export const PARTNER_CAMPAIGN_SOLVE_ISSUES_INTERACTOR_PROVIDER = [
  {
    provide: SolvePartnerCampaignIssuesInteractor,
    deps: [PartnerCampaignProvider, PartnerCampaignService],
    useFactory: (
      partnerCampaignProvider: PartnerCampaignProvider,
      partnerCampaignService: PartnerCampaignService,
    ) =>
      partnerCampaignProvider.getSolvePartnerCampaignIssues(
        partnerCampaignService,
      ),
  },
];

@NgModule({
  providers: [
    ...PARTNER_CAMPAIGN_PRE_APPROVE_INTERACTOR_PROVIDER,
    ...PARTNER_CAMPAIGN_SOLVE_ISSUES_INTERACTOR_PROVIDER,
    {
      provide: PartnerCampaignProvider,
      deps: [ApiRequestService],
      useFactory: (apiRequest: ApiRequestService) =>
        new PartnerCampaignDefaultProviders(apiRequest),
    },
  ],
})
export class PartnerCampaignProviderModule {}
