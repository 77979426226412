<span class="title">{{ "recoverPassword.title" | translate }}</span>

<form [formGroup]="form" novalidate>
  @if (!token) {
    <section>
      @if (state() !== "submitted") {
        <mat-form-field class="fieldset">
          <mat-label>{{ "shared.emailAddress" | translate }}</mat-label>
          <input matInput type="email" formControlName="email" />
          @if (form.controls.email.errors?.["required"]) {
            <mat-error>
              {{ "shared.emailAddressIsRequired" | translate }}
            </mat-error>
          } @else if (form.controls.email.errors?.["email"]) {
            <mat-error>
              {{ "shared.enterValidEmailAddress" | translate }}
            </mat-error>
          }
        </mat-form-field>
        <button
          class="btn btn--primary full-width"
          [disabled]="state() === 'submitting'"
          (click)="recoverPassword()"
        >
          {{ "recoverPassword.sendRecoveryEmail" | translate }}
        </button>
        <div class="switch-form">
          <a [routerLink]="['/login']">
            {{ "recoverPassword.backToLogin" | translate }}
          </a>
        </div>
      } @else {
        <div class="message-image">
          <img src="/assets/images/as_email_sent.png" />
          <p>{{ "recoverPassword.emailSent" | translate }}</p>
          <a [routerLink]="['/login']">
            {{ "recoverPassword.backToLogin" | translate }}
          </a>
        </div>
      }
    </section>
  } @else {
    <h1>{{ "recoverPassword.setNewPassword" | translate }}</h1>
    @if (state() !== "submitted") {
      <section>
        <mat-form-field>
          <mat-label>{{ "recoverPassword.newPassword" | translate }}</mat-label>
          <input matInput type="password" formControlName="password" />
          @if (form.controls.password.errors?.["required"]) {
            <mat-error>
              {{ "recoverPassword.passwordRequired" | translate }}
            </mat-error>
          }
        </mat-form-field>
      </section>
      <section>
        <button
          class="btn btn--primary full-width"
          (click)="savePassword()"
          [disabled]="state() === 'submitting'"
        >
          {{ "recoverPassword.updatePassword" | translate }}
        </button>
        <a [routerLink]="['/login']">
          {{ "recoverPassword.backToLogin" | translate }}
        </a>
      </section>
    } @else {
      <section>
        <img
          class="icon-check"
          src="/assets/images/icons/ic_check_orange.svg"
        />
      </section>
      <section>
        <p>{{ "recoverPassword.passwordUpdated" | translate }}</p>
        <a [routerLink]="['/login']">
          {{ "recoverPassword.backToLogin" | translate }}
        </a>
      </section>
    }
  }
</form>
