import {
  Component,
  EventEmitter,
  inject,
  input,
  OnInit,
  Output,
  signal,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatIconButton } from "@angular/material/button";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { CampaignPartner } from "../../shared/models/partnerCampaign";
import { UserService } from "../../shared/services/api/user.service";
import { LanguageService } from "../../shared/services/language.service";
import { CampaignService } from "../../shared/services/api/campaign.service";
import { NotificationService } from "../../shared/services/notification.service";
import { AuthenticationService } from "../../shared/services/api/authentication.service";
import { assert } from "../../shared/utils/assert";
import { CustomFormValidators } from "../../shared/validators/custom-form-validators";
import { UserAccessService } from "../user-access.service";
import { FeatureFlagsService } from "../../shared/services/feature-flags.service";
import { IntercomService } from "../../shared/services/intercom.service";

interface SignUpForm {
  readonly areTermsAccepted: FormControl<boolean | null>;
  readonly email: FormControl<string | null>;
  readonly password: FormControl<string | null>;
}

@Component({
  standalone: true,
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrl: "../auth.scss",
  imports: [
    TranslateModule,
    FormsModule,
    MatFormField,
    MatInput,
    MatIconButton,
    MatIcon,
    MatLabel,
    MatError,
    RouterLink,
    ReactiveFormsModule,
    MatCheckbox,
  ],
})
export class SignUpComponent implements OnInit {
  public readonly partner = input<CampaignPartner | undefined>();
  @Output() public readonly partnerSignedUp = new EventEmitter<void>();

  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly campaignService = inject(CampaignService);
  private readonly featureFlagsService = inject(FeatureFlagsService);
  private readonly intercomService = inject(IntercomService);
  private readonly languageService = inject(LanguageService);
  private readonly notificationService = inject(NotificationService);
  private readonly router = inject(Router);
  private readonly userAccessService = inject(UserAccessService);
  private readonly userService = inject(UserService);

  protected readonly isSubmitting = signal(false);
  protected readonly isSubmitted = signal(false);
  protected readonly shouldShowTermsError = signal(false);
  protected readonly showPassword = signal(false);
  protected readonly isBrandInvitedUser = signal(false);
  protected form?: FormGroup<SignUpForm>;
  protected brandActivationCode?: string;

  public ngOnInit(): void {
    this.form = new FormGroup<SignUpForm>({
      areTermsAccepted: new FormControl(false, [Validators.requiredTrue]),
      email: new FormControl(this.partner()?.email ?? null, [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl(null, [
        Validators.required,
        CustomFormValidators.pattern(CustomFormValidators.PASSWORD_PATTERN),
      ]),
    });

    if (this.partner()?.email) {
      this.form.get("email")?.disable();
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.email) {
        this.form?.controls.email.patchValue(params.email);
        this.form?.get("email")?.disable();
      }
    });

    this.brandActivationCode =
      this.activatedRoute.snapshot.params.activation_code;

    this.isBrandInvitedUser.set(!!(this.brandActivationCode || this.partner()));
  }

  protected signup(): void {
    if (!this.form?.valid) {
      this.shouldShowTermsError.set(
        !this.form?.controls.areTermsAccepted.value,
      );
      return;
    }

    this.isSubmitting.set(true);

    const email = this.form?.controls.email.value;
    const password = this.form?.controls.password.value;
    assert(email);
    assert(password);

    this.userService
      .signUp(
        email,
        password,
        this.campaignService.invitedCampaignId,
        this.brandActivationCode,
        this.languageService.locale,
        this.partner()?.id,
      )
      .subscribe({
        next: () => {
          if (this.partner()?.id) {
            this.isSubmitted.set(true);
            this.partnerSignedUp.emit();
          }
          this.login(email, password);
        },
        error: (error) => {
          this.isSubmitting.set(false);
          const errorObject = error;

          if (errorObject?.error?.key === "USER_ALREADY_EXISTS") {
            this.notificationService.error("invite.userAlreadyExists");
            return;
          } else if (errorObject.error?.details) {
            for (const errorName in errorObject.error.details) {
              if (errorName) {
                this.notificationService.error(
                  errorObject.error.details[errorName],
                );
              }
            }
          } else {
            this.notificationService.error("invite.invitationFailed");
          }
        },
      });
  }

  private login(email: string, password: string): void {
    this.authenticationService.login(email, password).subscribe({
      next: async () => {
        await this.featureFlagsService.load();
        this.refreshUserData();
      },
      error: () => {
        this.isSubmitting.set(false);
        this.notificationService.error("invite.loginFailed");
      },
    });
  }

  private refreshUserData(): void {
    this.userAccessService.setUserData().subscribe(() => {
      this.isSubmitting.set(false);
      void this.intercomService.init();
      void this.router.navigate(["welcome"]);
    });
  }
}
