<div
  class="background hide-for-small"
  [style.background-image]="'url(' + signUpBackgroundImage() + ')'"
  [ngClass]="{ 'background--has-image': !!signUpBackgroundImage() }"
>
  @if (!signUpBackgroundImage()) {
    <img src="assets/images/as_socialpals_logotype.svg" alt="socialPALS" />
  }
</div>
<div class="content">
  <app-lang-selector class="header-section" mode="dark" />
  <div class="logos">
    @if (brandLogo(); as logo) {
      <img
        class="brand-logo"
        [src]="logo"
        alt="{{ brandCampaign?.brand?.companyName }}"
      />
      <mat-icon>close</mat-icon>
    }
    <img
      src="assets/images/SP-orange-logo.svg"
      alt="socialPALS"
      [ngClass]="{ 'show-for-small-only': layoutType() === 'auth' }"
    />
  </div>
  @if (!isUnderMaintenance()) {
    <div class="form">
      @if (layoutType() === "invite") {
        <div class="campaign-content">
          @if (campaignNotFound()) {
            <h3 class="greeting-text">
              {{ "partner.campaign.campaignNotFound" | translate }}
            </h3>
          } @else if (campaign?.brand?.companyName && campaign?.name) {
            <span
              class="greeting"
              [innerHtml]="
                'partner.campaign.helloMessage'
                  | translate
                    : {
                        brandName: campaign?.brand?.companyName,
                        campaignName: campaign?.name
                      }
              "
            >
            </span>
          }
        </div>
      }

      @if (loadingKey() || successKey() || errorKey()) {
        <app-message
          [type]="messageType()"
          [messageKey]="errorKey() ?? loadingKey() ?? successKey()"
          [showLoading]="!!loadingKey()"
          [icon]="messageIcon()"
        />

        @if (errorKey()) {
          <a [routerLink]="['/login']">
            {{ "recoverPassword.backToLogin" | translate }}
          </a>
        }
      }

      @if (
        layoutType() === "invite" && inviteType() === "sign-up" && !isPreview()
      ) {
        <app-sign-up
          [partner]="partner"
          (partnerSignedUp)="onEventPartnerSignedUp()"
        />
      } @else {
        <router-outlet />
      }

      @if (inviteType() === "invite" && !loadingKey() && !successKey()) {
        <app-partner-accept-campaign
          (partnerAnsweredInvite)="onEventPartnerAnsweredInvite($event)"
        />
      }

      <div class="legal-links">
        <div
          class="legal-link dark"
          [innerHtml]="'shared.privacyPolicy' | withLink: 'url.privacy'"
        ></div>
        <span class="separator">|</span>
        <div
          class="legal-link dark"
          [innerHtml]="'shared.termsAndConditions' | withLink: 'url.terms'"
        ></div>
        <span class="separator">|</span>
        <a
          class="legal-link dark"
          href="https://www.socialpals.de"
          target="_blank"
        >
          socialPALS
        </a>
      </div>
    </div>
  } @else {
    <div class="maintenance-body">
      <div class="maintenance-body-text">
        {{ "shared.appUnderMaintenance" | translate }}
      </div>
      <div class="maintenance-body-sub-text">
        {{ "shared.appUnderMaintenanceDescription" | translate }}
      </div>
    </div>
  }
</div>
