<div class="alert {{ type() }}">
  <div class="icon-container">
    @if (icon()) {
      <mat-icon>{{ icon() }}</mat-icon>
    } @else if (showLoading()) {
      <mat-spinner diameter="20" />
    }
  </div>
  <span>{{ messageKey() | translate }}</span>
</div>
