import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserRole } from "../shared/models/user";
import { AuthenticationService } from "../shared/services/api/authentication.service";
import { BrandService } from "../shared/services/api/brand.service";
import { PartnerService } from "../shared/services/api/partner.service";
import { UserService } from "../shared/services/api/user.service";
import { NotificationService } from "../shared/services/notification.service";
import {
  StorageKeys,
  LocalStorageService,
} from "../shared/services/local-storage.service";

@Injectable({
  providedIn: "root",
})
export class UserAccessService {
  private readonly authService = inject(AuthenticationService);
  private readonly brandService = inject(BrandService);
  private readonly notificationService = inject(NotificationService);
  private readonly partnerService = inject(PartnerService);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly userService = inject(UserService);

  public setUserData(): Observable<void> {
    return this.userService.get().pipe(
      map(
        (user) => {
          this.authService.setUser(user);

          if (user.role === UserRole.Partner) {
            this.brandService.clearCurrentBrand();
            this.partnerService.getPartners().subscribe({
              next: (partners) => {
                if (partners.length > 0) {
                  // check if there was a previously selected partner
                  const previouslySelectedPartnerId =
                    this.localStorageService.getWithFallback(
                      StorageKeys.CurrentPartnerId,
                    );

                  if (previouslySelectedPartnerId) {
                    const isPartnerInTheList = partners.find(
                      (p) => p.id === +previouslySelectedPartnerId,
                    );

                    if (isPartnerInTheList) {
                      // otherwise set the first partner as the current partner
                      this.partnerService.setCurrentPartnerId(
                        +previouslySelectedPartnerId,
                      );
                      return;
                    }
                  }

                  // otherwise set the first partner as the current partner
                  this.partnerService.setCurrentPartnerId(partners[0].id);
                } else {
                  this.partnerService.clearCurrentPartner();
                }
              },
              error: () => {
                this.notificationService.error(
                  "shared.errorLoadingCurrentPartner",
                );
              },
            });
          } else if (user.role === UserRole.Brand) {
            this.partnerService.clearCurrentPartner();
            // set the first brand as the current brand ID
            // note: users cannot switch between brands yet,
            // as they can switch between partners
            this.brandService.getBrands().subscribe({
              next: (brands) => {
                if (brands.length > 0) {
                  const previouslySelectedBrandId = Number(
                    this.localStorageService.getWithFallback(
                      StorageKeys.CurrentBrandId,
                    ) ?? "-1",
                  );

                  // check if there was a previously selected brand
                  const isBrandInTheList = brands.find(
                    (b) => b.id === previouslySelectedBrandId,
                  );

                  if (isBrandInTheList) {
                    // todo: check if after loging out/in the brand is still loading
                    // otherwise set the first brand as the current brand
                    this.brandService.setCurrentBrandId(
                      previouslySelectedBrandId,
                    );
                    return;
                  }

                  this.brandService.setCurrentBrandId(brands[0].id);
                } else {
                  this.brandService.clearCurrentBrand();
                }
              },
              error: () => {
                this.notificationService.error(
                  "shared.errorLoadingCurrentBrand",
                );
              },
            });
          }
        },
        () => {
          this.notificationService.error("shared.errorLoadingUser");
        },
      ),
    );
  }
}
