<div class="container">
  <div class="floating-box">
    <div class="floating-box__header">
      <h1 class="floating-box__title">
        {{ "user.changePassword.title" | translate }}
      </h1>
    </div>
    <div class="floating-box__content">
      <form [formGroup]="form" novalidate>
        <fieldset>
          <mat-form-field
            class="floating-box__input-container floating-box__input-container--no-bottom-margin"
          >
            <mat-label>{{
              "user.changePassword.currentPassword" | translate
            }}</mat-label>
            <input
              matInput
              formControlName="currentPassword"
              type="password"
              autocomplete="off"
            />
            @if (form.controls.currentPassword.errors?.incorrectPassword) {
              <mat-error>
                {{ "user.changePassword.noMatch" | translate }}
              </mat-error>
            } @else if (form.controls.currentPassword.errors?.required) {
              <mat-error>
                {{ "user.changePassword.currentPasswordRequired" | translate }}
              </mat-error>
            }
          </mat-form-field>
        </fieldset>

        <fieldset class="new-password-fieldset">
          <mat-form-field
            class="floating-box__input-container floating-box__input-container--no-bottom-margin"
          >
            <mat-label>
              {{ "user.changePassword.newPassword" | translate }}
            </mat-label>
            <input
              matInput
              formControlName="newPassword"
              [type]="showPassword() ? 'text' : 'password'"
              type="password"
              autocomplete="off"
            />
            <button
              mat-icon-button
              matSuffix
              (click)="showPassword.set(!showPassword())"
              [attr.aria-label]="
                (showPassword() ? 'invite.hidePassword' : 'invite.showPassword')
                  | translate
              "
              [attr.aria-pressed]="!showPassword()"
            >
              <mat-icon>
                {{ showPassword() ? "visibility" : "visibility_off" }}
              </mat-icon>
            </button>
            @if (form.controls.newPassword.errors?.required) {
              <mat-error>
                {{ "user.changePassword.newPasswordRequired" | translate }}
              </mat-error>
            } @else if (form.controls.newPassword.errors?.pattern) {
              <mat-error>
                {{ "invite.passwordPattern" | translate }}
              </mat-error>
            }
          </mat-form-field>
        </fieldset>
      </form>
    </div>
  </div>
  <div class="action-bar">
    <button
      class="action-bar__button action-bar__button--cancel"
      type="button"
      [routerLink]="['/user/profile']"
    >
      {{ "shared.cancel" | translate }}
    </button>
    <button
      (click)="submit()"
      class="action-bar__button action-bar__button--save"
      type="submit"
    >
      {{ "shared.save" | translate }}
    </button>
  </div>
</div>
