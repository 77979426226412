@if (isStatusInvited()) {
  <div class="notice notice--success">
    <div>
      <p class="notice__text">
        {{
          "partner.campaign.header.campaignAcceptInvitationTitle" | translate
        }}
      </p>
      <p class="notice__text">
        {{
          "partner.campaign.header.campaignAcceptInvitationSubTitle" | translate
        }}
      </p>
    </div>
  </div>
}
@if (isStatusCancelled()) {
  <div class="notice notice--warning">
    <div>
      <p class="notice__text">
        {{ "partner.campaign.header.campaignCancelledTitle" | translate }}
      </p>
      <p class="notice__text">
        {{ "partner.campaign.header.campaignCancelledMessage" | translate }}
      </p>
    </div>
  </div>
}

@if (isGeneratingContentCalendar() && !isPreApproved()) {
  <div class="notice">
    <div>
      <p class="notice__text">
        {{
          "partner.campaign.header.generatingContentCalendarTitle" | translate
        }}
      </p>
      <p class="notice__text">
        {{
          "partner.campaign.header.generatingContentCalendarMessage" | translate
        }}
      </p>
    </div>
    <button class="notice__link" (click)="onActionPreApprove()">
      {{ "partner.campaign.header.pendingPartnerApprovalMessage" | translate }}
    </button>
  </div>
}

@if (isGeneratingContentCalendar() && isPreApproved()) {
  <div class="notice">
    <div>
      <p class="notice__text">
        {{
          "partner.campaign.header.generatingContentCalendarTitle" | translate
        }}
      </p>
      <p class="notice__text">
        {{
          "partner.campaign.header.generatingContentCalendarMessage" | translate
        }}
      </p>
    </div>
    <div class="status-label">
      <mat-icon class="status-label__icon">check</mat-icon>
      <span class="status-label__text">{{
        "partner.campaign.header.pendingPartnerApprovalSentMessage" | translate
      }}</span>
    </div>
  </div>
}

@if (canInitiatePreApproval()) {
  <div class="notice">
    <p class="notice__text">
      <mat-icon class="notice__icon">check</mat-icon>
      {{ "partner.campaign.header.pendingPartnerApprovalTitle" | translate }}
    </p>
    <button class="notice__link" (click)="onActionPreApprove()">
      {{ "partner.campaign.header.pendingPartnerApprovalMessage" | translate }}
    </button>
  </div>
}

@if (isStatusPendingAndPreApproved()) {
  <div class="notice">
    <p class="notice__text">
      <mat-icon class="notice__icon">check</mat-icon>
      {{
        "partner.campaign.header.pendingPartnerApprovalSentTitle" | translate
      }}
    </p>
    <div class="status-label">
      <mat-icon class="status-label__icon">check</mat-icon>
      <span class="status-label__text">{{
        "partner.campaign.header.pendingPartnerApprovalSentMessage" | translate
      }}</span>
    </div>
  </div>
}

@if (isPendingPreApprovalExecution()) {
  <div class="notice">
    <p class="notice__text">
      {{
        "partner.campaign.header.pendingPreApprovalExecutionMessage" | translate
      }}
    </p>
  </div>
}
@if (approvalFailed()) {
  <div class="notice">
    <p class="notice__text">
      {{ "partner.campaign.header.approvalFailedMessage" | translate }}
    </p>
    <button class="notice__link" (click)="onActionSolveIssues()">
      {{ "partner.campaign.header.approvalSolveIssues" | translate }}
    </button>
  </div>
}
