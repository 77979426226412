@if (language(); as currentLang) {
  <button class="button">
    <img src="/assets/images/icons/flags/ic_flag_{{ currentLang.code }}.png" />
    <div class="arrow-down" [ngClass]="{ '--dark': mode() === 'dark' }"></div>
  </button>
  <ul class="lang-menu-list">
    @for (lang of languages(); track lang) {
      <li class="lang-menu-item">
        <a
          class="lang-menu-item-anchor"
          (click)="setLocale(lang.code)"
          id="edit-profile"
        >
          {{ lang.name }}
          <img
            src="/assets/images/icons/flags/ic_flag_{{ lang.code }}.png"
            alt="{{ lang.code }}"
          />
        </a>
      </li>
    }
  </ul>
}
