@if (loginError()) {
  <div class="error">
    <div class="error-action">
      <img height="200" src="/src/assets/images/reload-page-man.png" />
      <div class="error-action-text">
        {{ "iframeSso.ssoLoginFailedMessage" | translate }}
      </div>
    </div>
  </div>
} @else {
  <div class="sso">
    <div class="sso-loading">
      <mat-progress-bar [color]="'primary'" mode="buffer" />
    </div>
  </div>
}
