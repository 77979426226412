import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { Router } from "@angular/router";
import { AuthenticationService } from "../services/api/authentication.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    if (this.authenticationService.isUserLogged) {
      return true;
    }

    const returnUrl = state.url;
    this.router.navigate(["/login"], { queryParams: { returnUrl: returnUrl } });
    return false;
  }
}
