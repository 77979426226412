import { Component, EventEmitter, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { PaymentType } from "../../../shared/enums/campaign.enums";
import {
  CampaignPostContentType,
  PostPartnerStatus,
} from "../../../shared/enums/campaignPost.enums";
import { Partner } from "../../../shared/models/partner";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerFacebookPostLog } from "../../../shared/models/partnerFacebookPostLog";
import { PostLog } from "../../../shared/models/postLog";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";
import { ImageService } from "../../../shared/services/image.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { UpdateFacebookPostLogData } from "../../../shared/services/parameters/update-facebook-post-log-data";
import { RequestErrorService } from "../../../shared/services/request-error.service";
import { PartnerCampaignPromoteAdDialogData } from "../../partner-campaign-promote-ad-dialog/partner-campaign-promote-ad-dialog-data";
import { PartnerCampaignPromoteAdDialogComponent } from "../../partner-campaign-promote-ad-dialog/partner-campaign-promote-ad-dialog.component";
import { SavePostLogData } from "../../partner-campaign-publish-post-time-picker/save-post-log-data";
import { PartnerDialogService } from "../../partner-dialog.service";
import { PartnerCampaignPostLogService } from "../../shared/services/partner-campaign-post-log.service";
import { PostEditDialog } from "../shared/post-edit-dialog";

export interface PartnerCampaignFacebookPostEditDialogData {
  readonly campaign: PartnerCampaign;
  readonly partner: Partner;
  readonly postLog: PartnerFacebookPostLog;
}

@Component({
  selector: "app-partner-campaign-facebook-post-edit-dialog",
  templateUrl: "./partner-campaign-facebook-post-edit-dialog.component.html",
  styleUrl: "./partner-campaign-facebook-post-edit-dialog.component.scss",
})
export class PartnerCampaignFacebookPostEditDialogComponent
  implements PostEditDialog<PartnerFacebookPostLog>
{
  public readonly campaignPostLogUpdatedEvent =
    new EventEmitter<PartnerFacebookPostLog>();
  public readonly postRemoved = new EventEmitter<PartnerFacebookPostLog>();

  protected readonly ContentType = CampaignPostContentType;
  protected readonly PartnerStatus = PostPartnerStatus;
  protected campaign: PartnerCampaign;
  protected partner: Partner;
  protected postLog: PartnerFacebookPostLog;
  protected saving = false;

  private readonly subscription = new Subscription();

  constructor(
    private readonly partnerCampaignPostLogService: PartnerCampaignPostLogService,
    private readonly imageService: ImageService,
    private readonly notificationService: NotificationService,
    private readonly requestErrorService: RequestErrorService,
    private readonly partnerCampaignService: PartnerCampaignService,
    private readonly dialog: MatDialog,
    private readonly partnerDialogService: PartnerDialogService,
    @Inject(MAT_DIALOG_DATA) data: PartnerCampaignFacebookPostEditDialogData,
  ) {
    this.postLog = data.postLog;
    this.campaign = data.campaign;
    this.partner = data.partner;
  }

  protected async handleSaveOrPublishButtonPressed(
    savePostLogData: SavePostLogData,
  ): Promise<void> {
    if (await this.isVatNumberMissing()) {
      return;
    }

    if (!this.partner.isConnectedToFB) {
      this.showFBConnectionModal();
      return;
    }

    if (
      savePostLogData.postToInstagram &&
      !this.partner.isConnectedToInstagram
    ) {
      this.showFBConnectionModal();
      return;
    }

    if (this.postLog.hasAd && this.postLog.ad!.isPaidAd) {
      this.updatePost(PostPartnerStatus.Approved, savePostLogData);
    } else if (savePostLogData.isPromoAd) {
      if (!this.partner.isAuthorizedFBAds) {
        this.dialog.closeAll();
        this.partnerDialogService.showPartnerAuthorizeFacebookAds({
          partner: this.partner,
        });
        return;
      }
      this.showPostAdDialog(savePostLogData);
    } else {
      this.updatePost(PostPartnerStatus.Approved, savePostLogData);
    }
  }

  private async showPostAdDialog(
    savePostLogData: SavePostLogData,
  ): Promise<void> {
    const postLog = savePostLogData.postLog as PartnerFacebookPostLog;
    if (!this.partner.isConnectedToFB) {
      await this.showFBConnectionModal();
      return;
    }

    if (
      savePostLogData.postToInstagram &&
      !this.partner.isConnectedToInstagram
    ) {
      await this.showFBConnectionModal();
      return;
    }

    if (!this.partner.isAuthorizedFBAds) {
      await this.partnerDialogService.showPartnerAuthorizeFacebookAds({
        partner: this.partner,
      });
      return;
    }

    if (this.campaign.isPartnerPendingToDefineACustomCampaignBudget) {
      const result = await this.partnerDialogService.showConfirmBudgetDialog({
        campaign: this.campaign,
      });

      if (!result) {
        return;
      }
    }

    this.dialog.closeAll();
    const dialogRef = this.dialog.open(
      PartnerCampaignPromoteAdDialogComponent,
      {
        data: new PartnerCampaignPromoteAdDialogData(
          this.campaign,
          postLog,
          savePostLogData.scheduledPublishDateAndTime,
          this.partner,
          false,
          savePostLogData.postToFacebook,
          savePostLogData.postToInstagram,
        ),
        disableClose: true,
      },
    );

    this.subscription.add(
      dialogRef.componentInstance.adCreatedOrUpdated.subscribe(
        (postLogUpdated: PostLog) => {
          this.campaignPostLogUpdatedEvent.emit(
            postLogUpdated as PartnerFacebookPostLog,
          );
        },
      ),
    );
  }

  protected updatePost(
    partnerStatus?: PostPartnerStatus,
    partnerCampaignSaveButtonPressed?: SavePostLogData,
  ): void {
    this.saving = true;
    if (partnerCampaignSaveButtonPressed) {
      this.postLog.willBecomeAd = partnerCampaignSaveButtonPressed.isPromoAd;
      this.postLog.scheduledPublishDate =
        partnerCampaignSaveButtonPressed.scheduledPublishDateAndTime;
      this.postLog.postToFacebook =
        partnerCampaignSaveButtonPressed.postToFacebook;
      this.postLog.postToInstagram =
        partnerCampaignSaveButtonPressed.postToInstagram;
    }

    if (!this.partner.isConnectedToFB) {
      this.postLog.partnerStatus = PostPartnerStatus.Pending;
    } else if (partnerStatus) {
      this.postLog.partnerStatus = partnerStatus;
    }

    this.partnerCampaignPostLogService
      .updateCampaignPost(
        this.postLog.id,
        new UpdateFacebookPostLogData(this.postLog, this.postLog.partnerStatus),
      )
      .subscribe({
        next: (updatedPost) => {
          this.postLog = updatedPost as PartnerFacebookPostLog;
          this.saving = false;
          this.dialog.closeAll();
          this.notificationService.success("shared.changesSavedSuccessfully");
          this.campaignPostLogUpdatedEvent.emit(this.postLog);
        },
        error: (err) => {
          const error = err.error;
          this.saving = false;
          if (error && error.key && error.key === "FB_PARTNER_AUTH_ERROR") {
            this.requestErrorService.partnerNotConnectedError();
          } else {
            this.notificationService.error(
              "shared.publishErrorInvalidTime",
              "shared.changesCouldNotBeSaved",
            );
          }
        },
      });
  }

  protected async cancelPost(): Promise<void> {
    const isDeleted = await this.partnerDialogService.showDeletePost({
      post: this.postLog,
    });

    if (isDeleted) {
      this.postRemoved.emit(this.postLog);
      this.dialog.closeAll();
    }
  }

  protected getImageUrl(imageUrl: string, imagePublicId: string): string {
    return this.imageService.resizeImage(imageUrl, 1000, imagePublicId);
  }

  protected isAPostLogWithAlreadyPaidAd(): boolean {
    return this.postLog.hasAd && this.postLog.ad!.isPaidAd;
  }

  private async showFBConnectionModal(): Promise<void> {
    await this.partnerDialogService.showFacebookNotConnected({
      partner: this.partner,
    });
  }

  private async isVatNumberMissing(): Promise<boolean> {
    if (
      this.postLog.hasAd &&
      this.campaign.paymentType !== PaymentType.Brand &&
      !this.partner.vatNumber
    ) {
      const response = await this.partnerDialogService.showVatInfoDialog({
        partner: this.partner,
      });

      if (!(response instanceof Partner) || !response.vatNumber) {
        return true;
      }
      this.partner.vatNumber = response.vatNumber;
    }
    return false;
  }
}
