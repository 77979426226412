import { Component, EventEmitter, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { PaymentType } from "../../../shared/enums/campaign.enums";

import { PostPartnerStatus } from "../../../shared/enums/campaignPost.enums";
import { Partner } from "../../../shared/models/partner";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerGoogleAdPostLog } from "../../../shared/models/partnerGoogleAdPostLog";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { UpdateGoogleAdPostLogData } from "../../../shared/services/parameters/update-google-ad-post-log-data";
import { PartnerCampaignPromoteAdDialogData } from "../../partner-campaign-promote-ad-dialog/partner-campaign-promote-ad-dialog-data";
import { PartnerCampaignPromoteAdDialogComponent } from "../../partner-campaign-promote-ad-dialog/partner-campaign-promote-ad-dialog.component";
import { SavePostLogData } from "../../partner-campaign-publish-post-time-picker/save-post-log-data";
import { PartnerDialogService } from "../../partner-dialog.service";
import { PartnerCampaignPostLogService } from "../../shared/services/partner-campaign-post-log.service";
import { PostEditDialog } from "../shared/post-edit-dialog";

export interface PartnerCampaignGoogleAdEditDialogData {
  readonly campaign: PartnerCampaign;
  readonly partner: Partner;
  readonly postLog: PartnerGoogleAdPostLog;
}

@Component({
  templateUrl: "./partner-campaign-google-ad-edit-dialog.component.html",
  styleUrl: "./partner-campaign-google-ad-edit-dialog.component.scss",
})
export class PartnerCampaignGoogleAdEditDialogComponent
  implements OnDestroy, PostEditDialog<PartnerGoogleAdPostLog>
{
  public readonly campaignPostLogUpdatedEvent =
    new EventEmitter<PartnerGoogleAdPostLog>();
  public readonly postRemoved = new EventEmitter<PartnerGoogleAdPostLog>();

  protected readonly PostPartnerStatus = PostPartnerStatus;
  protected campaign: PartnerCampaign;
  protected hasError = false;
  protected partner: Partner;
  protected postLog: PartnerGoogleAdPostLog;
  protected saving = false;

  private readonly subscription = new Subscription();

  constructor(
    private readonly partnerCampaignPostLogService: PartnerCampaignPostLogService,
    private readonly notificationService: NotificationService,
    private readonly partnerCampaignService: PartnerCampaignService,
    private readonly partnerDialogService: PartnerDialogService,
    private readonly dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: PartnerCampaignGoogleAdEditDialogData,
  ) {
    this.postLog = data.postLog;
    this.campaign = data.campaign;
    this.partner = data.partner;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected hasFormError(hasFormError: boolean): void {
    this.hasError = hasFormError;
  }

  protected async handleSaveOrPublishButtonPressed(
    postLogData: SavePostLogData,
  ): Promise<void> {
    if (this.campaign.isPartnerPendingToDefineACustomCampaignBudget) {
      const result = await this.partnerDialogService.showConfirmBudgetDialog({
        campaign: this.campaign,
      });

      if (!result) {
        return;
      }
    }

    if (await this.isVatNumberMissing()) {
      return;
    }

    if (this.postLog.hasAd && this.postLog.ad!.isPaidAd) {
      this.updatePost(PostPartnerStatus.Approved, postLogData);
    } else if (!this.partner.areGoogleAdsAuthorized) {
      this.showGoogleAuthModal();
    } else {
      const dialogRef = this.dialog.open(
        PartnerCampaignPromoteAdDialogComponent,
        {
          data: new PartnerCampaignPromoteAdDialogData(
            this.campaign,
            postLogData.postLog,
            postLogData.scheduledPublishDateAndTime,
            this.partner,
            false,
            false,
            false,
          ),
          disableClose: true,
        },
      );

      this.subscription.add(
        dialogRef.componentInstance.adCreatedOrUpdated.subscribe(
          (adCreatedOrUpdated: PartnerGoogleAdPostLog) => {
            this.campaignPostLogUpdatedEvent.emit(adCreatedOrUpdated);
          },
        ),
      );
    }
  }

  protected updatePost(
    partnerStatus: PostPartnerStatus,
    postLogData?: SavePostLogData,
  ): void {
    this.saving = true;

    if (!this.partner.areGoogleAdsAuthorized) {
      this.postLog.partnerStatus = PostPartnerStatus.Pending;
    } else if (partnerStatus) {
      this.postLog.partnerStatus = partnerStatus;
    }

    if (postLogData) {
      this.postLog.scheduledPublishDate =
        postLogData.scheduledPublishDateAndTime;
    }

    this.subscription.add(
      this.partnerCampaignPostLogService
        .updateCampaignPost(
          this.postLog.id,
          new UpdateGoogleAdPostLogData(
            this.postLog,
            this.postLog.partnerStatus,
          ),
        )
        .subscribe({
          next: (postLog) => {
            this.saving = false;

            if (!this.partner.areGoogleAdsAuthorized) {
              this.showGoogleAuthModal();
            } else {
              this.dialog.closeAll();
              this.notificationService.success(
                "shared.changesSavedSuccessfully",
              );
            }

            this.campaignPostLogUpdatedEvent.emit(
              postLog as PartnerGoogleAdPostLog,
            );
          },
          error: () => {
            this.saving = false;
            this.notificationService.error("shared.changesCouldNotBeSaved");
          },
        }),
    );
  }

  protected async cancelPost(): Promise<void> {
    const isDeleted = await this.partnerDialogService.showDeletePost({
      post: this.postLog,
    });

    if (isDeleted) {
      this.postRemoved.emit(this.postLog);
    }
  }

  private showGoogleAuthModal(): void {
    this.partnerDialogService.showGoogleAdsConnection();
  }

  private async isVatNumberMissing(): Promise<boolean> {
    if (
      this.postLog.hasAd &&
      this.campaign.paymentType !== PaymentType.Brand &&
      !this.partner.vatNumber
    ) {
      const response = await this.partnerDialogService.showVatInfoDialog({
        partner: this.partner,
      });

      if (!(response instanceof Partner) || !response.vatNumber) {
        return true;
      }
      this.partner.vatNumber = response.vatNumber;
    }
    return false;
  }
}
