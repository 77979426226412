import { PutInteractor } from "../../../../../harmony/core";
import { SolvePartnerCampaignIssuesNetworkQuery } from "../../data/queries/solve-partner-campaign-issues-network.query";
import { SolveIssuesResponse } from "../models/solve-issues-response";

export class SolvePartnerCampaignIssuesInteractor {
  constructor(
    protected readonly solveIssues: PutInteractor<SolveIssuesResponse>,
  ) {}

  public async execute(
    partnerId: number,
    campaignId: number,
  ): Promise<SolveIssuesResponse> {
    return await this.solveIssues.execute(
      undefined,
      new SolvePartnerCampaignIssuesNetworkQuery(partnerId, campaignId),
    );
  }
}
