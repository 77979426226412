import { SolveIssuesResponse } from "../domain/models/solve-issues-response";
import { SolveIssuesResponseEntity } from "./entities/solve-issues-response.entity";

export class SolveIssuesResponseEntityToSolveIssuesResponseMapper {
  public map(from: SolveIssuesResponseEntity): SolveIssuesResponse {
    return new SolveIssuesResponse(
      from?.pendingPostsBefore,
      from?.pendingPostsAfter,
    );
  }
}

export class SolveIssuesResponseToSolveIssuesResponseEntityMapper {
  public map(from: SolveIssuesResponse): SolveIssuesResponseEntity {
    return new SolveIssuesResponseEntity(
      from?.pendingPostsBefore,
      from?.pendingPostsAfter,
    );
  }
}
