import { Component, inject, signal } from "@angular/core";
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatIconButton } from "@angular/material/button";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { Router, RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { lastValueFrom } from "rxjs";

import { User } from "../../shared/models/user";
import { AuthenticationService } from "../../shared/services/api/authentication.service";
import { UserService } from "../../shared/services/api/user.service";
import { NotificationService } from "../../shared/services/notification.service";
import { CustomFormValidators } from "../../shared/validators/custom-form-validators";
import { assert } from "../../shared/utils/assert";

@Component({
  standalone: true,
  selector: "app-edit-password",
  templateUrl: "./edit-password.component.html",
  styleUrls: ["./edit-password.component.scss"],
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    MatFormField,
    MatInput,
    MatIconButton,
    MatIcon,
    MatLabel,
    RouterLink,
  ],
})
export class EditPasswordComponent {
  private readonly userService = inject(UserService);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly notificationService = inject(NotificationService);
  private readonly router = inject(Router);
  private readonly formBuilder = inject(NonNullableFormBuilder);

  protected readonly showPassword = signal(false);
  protected form = this.formBuilder.group({
    currentPassword: ["", [Validators.required]],
    newPassword: [
      "",
      [
        Validators.required,
        Validators.pattern(CustomFormValidators.PASSWORD_PATTERN),
      ],
    ],
  });

  private async checkCurrentPassword(
    userName: string,
    currentPassword: string,
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.authenticationService.login(userName, currentPassword),
      );

      return true;
    } catch {
      this.form.get("currentPassword")?.setErrors({ incorrectPassword: true });
      this.form.markAllAsTouched();

      return false;
    }
  }

  protected async submit(): Promise<void> {
    if (this.form.valid) {
      const userName = this.authenticationService.getUserName();
      const currentPassword = this.form.getRawValue().currentPassword;
      const newPassword = this.form.getRawValue().newPassword;

      assert(userName, "User name is not defined.");

      if (!(await this.checkCurrentPassword(userName, currentPassword))) {
        return;
      }

      const user = new User();
      user.password = newPassword;

      await lastValueFrom(this.userService.update(user));

      this.notificationService.success("userAccount.userPasswordUpdated");
      this.router.navigateByUrl("/user/profile");
    }
  }
}
