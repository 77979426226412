import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, inject, Output, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Meta } from "@angular/platform-browser";
import { TranslateModule } from "@ngx-translate/core";
import { lastValueFrom } from "rxjs";
import { CampaignService } from "../../../shared/services/api/campaign.service";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { PartnerDeclineCampaignDialogComponent } from "../../../partner/partner-decline-campaign-dialog/partner-decline-campaign-dialog.component";
import { assert } from "../../../shared/utils/assert";

@Component({
  standalone: true,
  selector: "app-partner-accept-campaign",
  templateUrl: "./partner-accept-campaign.component.html",
  styleUrls: ["./partner-accept-campaign.component.scss"],
  imports: [FormsModule, TranslateModule],
})
export class PartnerAcceptCampaignComponent {
  private readonly campaignService = inject(CampaignService);
  private readonly partnerCampaignService = inject(PartnerCampaignService);
  private readonly notificationService = inject(NotificationService);
  private readonly router = inject(Router);
  private readonly dialog = inject(MatDialog);

  protected readonly isSubmitting = signal(false);
  protected readonly isSubmitted = signal(false);
  @Output() public readonly partnerAnsweredInvite = new EventEmitter<boolean>();

  constructor(meta: Meta) {
    meta.updateTag({
      name: "viewport",
      content: "width=device-width, initial-scale=1.0",
    });
  }

  protected acceptCampaign(): void {
    this.isSubmitting.set(true);
    this.partnerAnsweredInvite.emit(true);
    this.partnerCampaignService
      .partnerAcceptCampaignFromToken(this.getPartnerInviteToken())
      .subscribe({
        next: () => {
          this.isSubmitting.set(false);
          this.isSubmitted.set(true);
          setTimeout(() => {
            this.router.navigate(["welcome"]);
          }, 2000);
        },
        error: (error) => {
          this.showError(error);
        },
      });
  }

  protected async declineCampaignConfirm(): Promise<void> {
    const declineReason: string | undefined = await lastValueFrom(
      this.dialog
        .open(PartnerDeclineCampaignDialogComponent, {
          data: {
            message: "partner.campaignList.declineConfirmTitle",
          },
        })
        .afterClosed(),
    );

    if (declineReason) {
      this.declineCampaign(declineReason);
    }
  }

  protected declineCampaign(declineReason: string): void {
    this.isSubmitting.set(true);
    this.partnerAnsweredInvite.emit(false);
    this.partnerCampaignService
      .partnerDeclineCampaignFromToken(
        this.getPartnerInviteToken(),
        declineReason,
      )
      .subscribe({
        next: () => {
          this.isSubmitting.set(false);
          this.isSubmitted.set(true);
          setTimeout(() => {
            this.router.navigate(["welcome"]);
          }, 2000);
        },
        error: (error) => {
          this.showError(error);
        },
      });
  }

  private getPartnerInviteToken(): string {
    assert(
      this.campaignService.partnerInviteToken,
      "Partner invite token not defined",
    );
    return this.campaignService.partnerInviteToken;
  }

  private showError(err: HttpErrorResponse): void {
    this.isSubmitting.set(false);
    const error = err.error?.error;

    let message = "partner.acceptCampaign.errorJoiningCampaign";

    if (error?.key === "ALREADY_ACCEPTED") {
      message = "partner.acceptCampaign.alreadyAccepted";
    } else if (error?.key === "CAMPAIGN_ALREADY_FINISHED") {
      message = "partner.acceptCampaign.campaignIsAlreadyOver";
    } else if (error.details?.length) {
      message = err.error.error.details[0];
    }

    this.notificationService.error(message);
  }
}
