import { HttpBody, PutNetworkQuery } from "../../../../../harmony/core";
import { ApiEndpoint } from "../../../api-endpoint";

export class SolvePartnerCampaignIssuesNetworkQuery extends PutNetworkQuery {
  constructor(
    private readonly partnerId: number,
    private readonly campaignId: number,
  ) {
    super(ApiEndpoint.SolveIssues);
  }

  public get body(): HttpBody {
    return {
      campaignId: this.campaignId,
      partnerId: this.partnerId,
    };
  }
}
